import { CART_LISTING_TYPE } from '../../config/configListing';
import { currentUserShowSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import { cartTransactionLineItems } from '../../util/api';
import {
  denormalisedEntities,
  denormalisedResponseEntities,
  updatedEntities,
} from '../../util/data';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';
import {
  generateRandomEmail,
  getRandomDisplayName,
  getRandomPassword,
} from '../../util/auth-utils';
import { signup } from '../../ducks/auth.duck';

export const FETCH_LINE_ITEMS_REQUEST = 'app/CartPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_ERROR = 'app/CartPage/FETCH_LINE_ITEMS_ERROR';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/CartPage/FETCH_LINE_ITEMS_SUCCESS';

export const FETCH_LISTINGS_REQUEST = 'app/CartPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/CartPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/CartPage/FETCH_LISTINGS_ERROR';

export const ADD_CART_ENTITIES = 'app/CartPage/ADD_CART_ENTITIES';

export const SET_CURRENT_AUTHOR = 'app/CartPage/SET_CURRENT_AUTHOR';
export const SET_CURRENT_AUTHOR_DELIVERY = 'app/CartPage/SET_CURRENT_AUTHOR_DELIVERY';
export const SET_AUTHOR_ID = 'app/CartPage/SET_AUTHOR_ID';

export const TOGGLE_DELIVERY_REQUEST = 'app/CartPage/TOGGLE_DELIVERY_REQUEST';
export const TOGGLE_DELIVERY_SUCCESS = 'app/CartPage/TOGGLE_DELIVERY_SUCCESS';
export const TOGGLE_DELIVERY_ERROR = 'app/CartPage/TOGGLE_DELIVERY_ERROR';

export const TOGGLE_CART_REQUEST = 'app/CartPage/TOGGLE_CART_REQUEST';
export const TOGGLE_CART_ERROR = 'app/CartPage/TOGGLE_CART_ERROR';
export const TOGGLE_CART_SUCCESS = 'app/CartPage/TOGGLE_CART_SUCCESS';

export const REMOVE_AUTHOR_CART = 'app/CartPage/REMOVE_AUTHOR_CART';

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = result => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: result.data,
});
export const fetchLineItemsError = e => ({ type: FETCH_LINE_ITEMS_ERROR, error: true, payload: e });
export const fetchCartItemsReq = params => ({ type: FETCH_LISTINGS_REQUEST, payload: params });
export const fetchCartItemsErr = e => ({ type: FETCH_LISTINGS_ERROR, payload: e });
export const setAuthorIdx = id => ({ type: SET_AUTHOR_ID, payload: id });
export const setCurrentAuthorDelivery = deliveryMethod => ({
  type: SET_CURRENT_AUTHOR_DELIVERY,
  payload: deliveryMethod,
});
export const setCurrentAuthor = author => ({
  type: SET_CURRENT_AUTHOR,
  payload: author,
});
export const addCartEntities = sdkResponse => ({ type: ADD_CART_ENTITIES, payload: sdkResponse });
export const queryListingsSuccess = (sdkResponse, cart) => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: sdkResponse.data, cart },
});
export const toggleDeliveryRequest = () => ({ type: TOGGLE_DELIVERY_REQUEST });
export const toggleDeliverySuccess = result => ({
  type: TOGGLE_DELIVERY_SUCCESS,
  payload: result,
});
export const toggleDeliveryError = e => ({ type: TOGGLE_DELIVERY_ERROR, error: true, payload: e });
export const toggleCartRequest = () => ({ type: TOGGLE_CART_REQUEST });
export const toggleCartSuccess = result => ({
  type: TOGGLE_CART_SUCCESS,
  payload: result,
});
export const toggleCartError = e => ({ type: TOGGLE_CART_ERROR, error: true, payload: e });
export const removeAuthorCart = newCart => ({ type: REMOVE_AUTHOR_CART, payload: newCart });

export const deliveryOptions = {
  BOTH: 'both',
  SHIPPING: 'shipping',
  PICKUP: 'pickup',
  NONE: 'none',
};

const RESULT_PAGE_SIZE = 8;

export const getCartListingIds = cart => Object.keys(cart).filter(el => el !== 'deliveryMethod');

const updateCurrentUserCart = newCart => (dispatch, getState, sdk) => {
  return sdk.currentUser
    .updateProfile(
      {
        privateData: {
          cart: newCart,
        },
      },
      { expand: true }
    )
    .then(resp => {
      const entities = denormalisedResponseEntities(resp);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }
      const currentUser = entities[0];

      // Update current user in state.user.currentUser through user.duck.js
      dispatch(currentUserShowSuccess(currentUser));

      // Return the updated cart
      return resp.data.data.attributes.profile.privateData.cart;
    });
};

export const toggleCart = (listingId, authorId, config, increment = 1) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(toggleCartRequest);
  const state = getState();

  const currentUser = state.user.currentUser;
  const cart = currentUser.attributes.profile.privateData?.cart || {};

  let newCart = getNewCart(cart, authorId, listingId, increment);

  dispatch(updateCurrentUserCart(newCart))
    .then(updatedCart => {
      if (
        !listingIsInCart(updatedCart, authorId, listingId) ||
        Object.keys(updatedCart) !== Object.keys(cart)
      ) {
        let newAuthorId;
        if (!updatedCart[authorId] && Object.keys(updatedCart).length) {
          const authors = Object.keys(state.CartPage.cart);
          const authorIdx = authors.findIndex(el => el === authorId);
          const newAuthorIdx = authorIdx + 1 < authors?.length ? authorIdx + 1 : 0;
          newAuthorId = authors[newAuthorIdx];
          dispatch(setAuthorIdx(newAuthorIdx));
        }

        dispatch(queryCartListings(state.CartPage.queryParams, config, newAuthorId ?? authorId));
      }

      dispatch(getCartLineItems(null, updatedCart));

      if (Object.keys(updatedCart).length === 0) {
        dispatch(setCurrentAuthor(null));
      }

      dispatch(toggleCartSuccess(updatedCart));
    })
    .catch(e => {
      dispatch(toggleCartError(storableError(e)));
    });
};

export const getCartLineItems = (currAuthor = null, updatedCart = null) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(fetchLineItemsRequest);
  let { cart, currentAuthor } = getState().CartPage;

  currentAuthor = currentAuthor ?? currAuthor;
  const currentCart = updatedCart ?? cart;

  if (!currentAuthor || !currentCart || !Object.keys(currentCart).length) {
    dispatch(fetchLineItemsSuccess({ data: [] }));
    return;
  }

  if (currentAuthor) {
    const authorCart = currentCart[(currentAuthor?.id?.uuid)];

    cartTransactionLineItems({
      isOwnListing: false,
      orderData: {
        cart: authorCart,
      },
    })
      .then(resp => {
        dispatch(fetchLineItemsSuccess(resp));
      })
      .catch(e => {
        dispatch(fetchLineItemsError(storableError(e)));
      });
  }
};

export const getCartListingsById = (state, listingIds) => {
  const { cartListings } = state.CartPage;
  const resources = listingIds.map(id => ({
    id,
    type: 'listing',
  }));
  return denormalisedEntities(cartListings, resources, false);
};

const resultIds = data => data.data.map(l => l.id);

export const clearCart = (authorId, config) => (dispatch, getState) => {
  const state = getState();

  const currentUser = state.user.currentUser;
  const cart = currentUser.attributes.profile.privateData?.cart || [];

  if (cart[authorId]) {
    delete cart[authorId];
  }

  dispatch(updateCurrentUserCart(cart))
    .then(updatedCart => {
      let newAuthorId;
      if (!updatedCart[authorId] && Object.keys(updatedCart).length) {
        const authors = Object.keys(updatedCart);
        const authorIdx = authors.findIndex(id => id === authorId);
        const newAuthorIdx = authorIdx + 1 < authors?.length ? authorIdx + 1 : 0;
        newAuthorId = authors[newAuthorIdx];
        dispatch(setAuthorIdx(newAuthorIdx));
      }
      dispatch(queryCartListings(state.CartPage.queryParams, config, newAuthorId ?? authorId));

      dispatch(getCartLineItems(null, updatedCart));

      if (Object.keys(updatedCart).length === 0) {
        dispatch(setCurrentAuthor(null));
      }

      dispatch(removeAuthorCart(updatedCart));
    })
    .catch(err => {
      console.error('clear cart err', { err });
    });
};

export const queryCartListings = (queryParams, config, authorId = null, currentUser = null) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(fetchCartItemsReq(queryParams));

  const user = currentUser?.attributes ?? getState().user.currentUser;
  const cart = user?.attributes.profile.privateData?.cart ?? {};

  const { currentAuthor } = getState().CartPage;
  const cartAuthorId = authorId ?? currentAuthor?.id.uuid ?? Object.keys(cart)[0];

  const { aspectWidth = 1, aspectHeight = 1 } = config.layout.listingImage;
  const variantPrefix = 'cart-card';
  const listingVariantPrefix = 'listing-card';
  const aspectRatio = aspectHeight / aspectWidth;

  const includeParams = {
    perPage: RESULT_PAGE_SIZE,
    include: ['images', 'author', 'currentStock'],
    'fields.image': [
      `variants.${variantPrefix}`,
      `variants.${listingVariantPrefix}`,
      `variants.${listingVariantPrefix}-2x`,
      `variants.${listingVariantPrefix}-4x`,
      `variants.${listingVariantPrefix}-6x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 100, aspectRatio),
    ...createImageVariantConfig(`${listingVariantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${listingVariantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${listingVariantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${listingVariantPrefix}-6x`, 2400, aspectRatio),
    'limit.images': 1,
  };

  const { perPage, ...rest } = { ...queryParams, ...includeParams };

  const ids = getAuthorListingIds(cartAuthorId, cart);

  const params = {
    ...rest,
    ids,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addCartEntities(response));
      const author = response.data?.included
        ?.filter(i => i.type === 'user')
        .find(el => el.id.uuid === cartAuthorId);

      if (cartAuthorId !== currentAuthor?.id.uuid && response.data.data.length !== 0) {
        dispatch(setCurrentAuthor(author));

        const authorDelivery =
          response.data.data[0].attributes.publicData.shippingEnabled &&
          response.data.data[0].attributes.publicData.pickupEnabled
            ? deliveryOptions.BOTH
            : response.data.data[0].attributes.publicData.pickupEnabled
            ? deliveryOptions.PICKUP
            : response.data.data[0].attributes.publicData.shippingEnabled
            ? deliveryOptions.SHIPPING
            : deliveryOptions.NONE;

        dispatch(setCurrentAuthorDelivery(authorDelivery));
      }

      dispatch(getCartLineItems(currentAuthor ?? author, cart));

      dispatch(queryListingsSuccess(response, cart));
      return response;
    })
    .catch(e => {
      dispatch(fetchCartItemsErr(storableError(e)));
      throw e;
    });
};

export const setCartDelivery = (authorId, delivery) => (dispatch, getState, sdk) => {
  dispatch(toggleDeliveryRequest());
  const currentUser = getState().user.currentUser;
  const { currentAuthor } = getState().CartPage;
  const cart = currentUser.attributes.profile.privateData?.cart || [];

  const isCurrentAuthor = authorId === currentAuthor?.id?.uuid;

  const isValidDelivery =
    delivery === deliveryOptions.PICKUP || delivery === deliveryOptions.SHIPPING;

  if (isValidDelivery && isCurrentAuthor) {
    const newCart = {
      ...cart,
      [authorId]: {
        ...cart[authorId],
        deliveryMethod: delivery,
      },
    };

    dispatch(updateCurrentUserCart(newCart))
      .then(updatedCart => {
        dispatch(toggleDeliverySuccess({ cart: updatedCart, delivery }));
        dispatch(getCartLineItems());
      })
      .catch(e => {
        dispatch(toggleDeliveryError(storableError(e)));
      });
  }
};

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  if (!apiResponse.data.length) {
    return {
      ...state,
      cartListings: {},
    };
  }

  return {
    ...state,
    cartListings: updatedEntities({ ...state.cartListings }, apiResponse),
  };
};

export const listingIsInCart = (cart, authorId, listingId) => {
  if (!cart || !cart[authorId]) {
    return false;
  }

  return Object.keys(cart[authorId]).includes(listingId);
};

const getAuthorListingIds = (cartAuthorId, cart) => {
  return (
    (cartAuthorId &&
      cart[cartAuthorId] &&
      Object.keys(cart[cartAuthorId]).filter(key => key !== 'deliveryMethod')) ||
    []
  );
};

const getNewCart = (cart, authorId, listingId, increment) => {
  const authorInCart = Object.keys(cart).includes(authorId);
  let isListingInCart = listingIsInCart(cart, authorId, listingId);

  const newCount = ((cart[authorId] && cart[authorId][listingId]?.count) || 0) + increment;

  // Increment an existing listing
  if (authorInCart && isListingInCart && newCount > 0) {
    return {
      ...cart,
      [authorId]: {
        ...cart[authorId],
        [listingId]: {
          count: newCount,
        },
      },
    };
    // Remove an existing listing from cart
  } else if (authorInCart && isListingInCart && newCount <= 0) {
    const newCart = { ...cart };
    delete newCart[authorId][listingId];

    const remainingCart = Object.keys(newCart[authorId]);

    // If the listing was the author's last one, remove the author as well
    if (
      remainingCart.length == 0 ||
      (remainingCart.length === 1 && remainingCart[0] === 'deliveryMethod')
    ) {
      delete newCart[authorId];
    }

    return newCart;
    // Add new listing to an existing author
  } else if (authorInCart && !isListingInCart) {
    return {
      ...cart,
      [authorId]: {
        ...cart[authorId],
        [listingId]: {
          count: increment,
        },
      },
    };
    // Add new listing and a new author
  } else {
    return {
      ...cart,
      [authorId]: {
        [listingId]: {
          count: increment,
        },
      },
    };
  }
};

const addShowcaseListingToCart = currentUserId => async (dispatch, getState, sdk) => {
  try {
    if (!currentUserId) {
      const params = {
        email: generateRandomEmail(),
        firstName: 'demo',
        lastName: 'user',
        password: getRandomPassword(),
        displayName: getRandomDisplayName(),
        publicData: {
          age: 27,
        },
        protectedData: {
          phoneNumber: '+1-202-555-5555',
        },
        privateData: {
          discoveredServiceVia: 'Twitter',
        },
      };

      await dispatch(signup(params));
    }
    const response = await sdk.listings.query({
      pub_listingType: CART_LISTING_TYPE,
      include: ['author', 'currentStock', 'images'],
    });
    const currentUser = getState().user.currentUser;
    response.data.data = response.data.data.filter(
      el => el.relationships.author.data.id.uuid !== currentUser.id.uuid
    );
    const cart = {};
    response.data.data.forEach(listing => {
      if (!cart[listing.relationships.author.data.id.uuid])
        cart[listing.relationships.author.data.id.uuid] = {};

      cart[listing.relationships.author.data.id.uuid][listing.id.uuid] = {};
      cart[listing.relationships.author.data.id.uuid][listing.id.uuid].count = 1;
    });

    const authorIds = Object.keys(cart);
    let authorId = authorIds.length === 1 ? authorIds[0] : null;
    if (!authorId) {
      for (let index = 0; index < authorIds.length; index++) {
        const el = authorIds[index];
        if (Object.keys(cart[el]).length > 1) {
          authorId = el;
          break;
        }
        if (Object.keys(cart[el]).length === 1) {
          authorId = el;
        }
      }
    }

    response.data.data = response.data.data.filter(
      el => el.relationships.author.data.id.uuid === authorId
    );

    dispatch(addCartEntities(response));

    const author = response.data?.included?.filter(
      i => i.type === 'user' && i.id.uuid === authorId
    )[0];

    dispatch(setCurrentAuthor(author));

    const authorDelivery =
      response.data.data[0].attributes.publicData.shippingEnabled &&
      response.data.data[0].attributes.publicData.pickupEnabled
        ? deliveryOptions.BOTH
        : response.data.data[0].attributes.publicData.pickupEnabled
        ? deliveryOptions.PICKUP
        : response.data.data[0].attributes.publicData.shippingEnabled
        ? deliveryOptions.SHIPPING
        : deliveryOptions.NONE;

    dispatch(setCurrentAuthorDelivery(authorDelivery));

    const showcaseCart = {};
    showcaseCart[authorId] = cart[authorId];
    dispatch(getCartLineItems(author, showcaseCart));
    dispatch(updateCurrentUserCart(showcaseCart));

    dispatch(queryListingsSuccess(response, showcaseCart));
  } catch (error) {
    console.error(error);
  }
};

export const loadData = (_, search, config, authorId = null) => (dispatch, _, sdk) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;
  const showcase = queryParams.showcase;

  return dispatch(fetchCurrentUser()).then(async currentUser => {
    if (showcase) {
      return dispatch(addShowcaseListingToCart(currentUser.id));
    }
    return dispatch(
      queryCartListings(
        {
          ...queryParams,
          page,
        },
        config,
        authorId
      )
    );
  });
};

const initialState = {
  authorIdx: 0,
  isFetchInProgress: false,
  fetchErr: null,
  cartListings: {},
  toggleDeliveryError: null,
  toggleDeliveryInProgress: false,
  currentAuthorDelivery: null,
  cart: {},
  pagination: null,
  toggleCartInProgress: false,
  toggleCartError: null,
  queryParams: null,
  lineItemsInProgress: false,
  cartLineItems: [],
  lineItemsError: null,
};

const CartPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return { ...state, isFetchInProgress: true, fetchErr: null, queryParams: payload };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        isFetchInProgress: false,
        cart: payload.cart,
      };
    case FETCH_LISTINGS_ERROR:
      return { ...state, isFetchInProgress: false, fetchErr: payload };

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, lineItemsInProgress: true, lineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, lineItemsInProgress: false, cartLineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, lineItemsInProgress: false, lineItemsError: payload };

    case TOGGLE_CART_REQUEST:
      return { ...state, toggleCartInProgress: true, toggleCartError: null };
    case TOGGLE_CART_SUCCESS:
      return { ...state, toggleCartInProgress: false, cart: payload };
    case TOGGLE_CART_ERROR:
      return { ...state, toggleCartInProgress: false, toggleCartError: payload };

    case ADD_CART_ENTITIES:
      return merge(state, payload);
    case SET_CURRENT_AUTHOR:
      return { ...state, currentAuthor: payload, currentAuthorDelivery: null };
    case SET_CURRENT_AUTHOR_DELIVERY:
      return { ...state, currentAuthorDelivery: payload };
    case SET_AUTHOR_ID:
      return { ...state, authorIdx: payload };

    case TOGGLE_DELIVERY_REQUEST:
      return { ...state, toggleDeliveryInProgress: true, toggleDeliveryError: null };
    case TOGGLE_DELIVERY_SUCCESS:
      return {
        ...state,
        toggleDeliveryInProgress: false,
        cart: payload.cart,
        currentAuthorDelivery: payload.delivery,
      };
    case TOGGLE_DELIVERY_ERROR:
      return { ...state, toggleDeliveryInProgress: false, toggleDeliveryError: payload };

    case REMOVE_AUTHOR_CART:
      return { ...state, cart: payload };
    default:
      return state;
  }
};

export default CartPageReducer;
