const words = [
    'apple', 'banana', 'cherry', 'date', 'elderberry', 'fig', 'grape', 'honeydew',
    'kiwi', 'lemon', 'mango', 'nectarine', 'orange', 'papaya', 'quince', 'raspberry',
    'strawberry', 'tangerine', 'ugli', 'vanilla', 'watermelon', 'xigua', 'yam', 'zucchini'
  ];

export const generateRandomEmail = () => {
    const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const domains = ['example.com', 'test.com', 'demo.com'];
    let email = '';

    // Generate random local part
    for (let i = 0; i < 10; i++) {
        email += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    // Append a random domain
    email += '@' + domains[Math.floor(Math.random() * domains.length)];

    return email;
}


export const getRandomWord = () => {
    return words[Math.floor(Math.random() * words.length)];
}

export const getRandomPassword = () => {
    return Array.from({ length: 4 }, () => getRandomWord(words)).join('');
}

export const getRandomDisplayName = () => {
    return `user${getRandomWord(words)}`
}
